import React from 'react';
import { I18nextProvider } from 'react-i18next';
import 'reset-css';
import DirectionProvider, { DIRECTIONS } from 'react-with-direction/dist/DirectionProvider';
import { initI18n, getIsDebugEnabled } from '@wix/communities-blog-client-common';
import { connect } from '../../../common/components/runtime-context';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import DirectionProviderWrapper from '../../../common/components/direction-provider-wrapper';
import { getIsRTL, getLanguage } from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import { initDebug } from '../../../common/services/debug';
import { initOuterUrl } from '../../../common/services/get-outer-url';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import AppLoaded from '../../../common/components/app-loaded';
import TagCloud from '../tag-cloud';
import styles from './app-root.scss';

class AppRoot extends React.Component {
  constructor(props) {
    super(props);
    initDebug(this.props.isDebugEnabled);
    initOuterUrl(this.props.sectionUrl);
  }

  getI18nConfig = (language, translations) => {
    if (this.lastLanguage !== language) {
      this.i18nConfig = initI18n(language, translations);
      this.lastLanguage = language;
    }
    return this.i18nConfig;
  };

  render() {
    const { language, translations, direction } = this.props;
    const i18n = this.getI18nConfig(language, translations);

    return (
      <ResponsiveListener>
        <I18nextProvider i18n={i18n}>
          <DirectionProviderWrapper>
            <DirectionProvider direction={direction}>
              <div className={styles.baseStyles}>
                <TagCloud />
                <AppLoaded />
              </div>
            </DirectionProvider>
          </DirectionProviderWrapper>
        </I18nextProvider>
      </ResponsiveListener>
    );
  }
}

const mapRuntimeToProps = state => ({
  language: getLanguage(state),
  translations: getTranslations(state),
  direction: getIsRTL(state) ? DIRECTIONS.RTL : DIRECTIONS.LTR,
  isDebugEnabled: getIsDebugEnabled(state),
  sectionUrl: getSectionUrl(state),
});

export default withReduxStore(connect(mapRuntimeToProps)(AppRoot));
